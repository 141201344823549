<template>
  <b-modal
    id="modal-additional-input-add"
    hide-header
    hide-footer
    centered
    no-close-on-backdrop
    title="Vertically Centered"
    ok-only
    ok-title="Accept"
  >
    <div class="p-2 d-flex justify-content-between">
      <div />
      <h1 class="h4 text-dark fw-bold-700 mb-0 size16">
        Masukkan biaya tambahan
      </h1>
      <feather-icon
        class="text-dark fw-bold-700"
        size="20"
        icon="XIcon"
        @click="$bvModal.hide('modal-additional-input-add')"
      />
    </div>
    <hr class="mt-0" />
    <b-container>
      <b-row class="p-2">
        <b-col cols="12">
          <b-form-group>
            <label for="jumlah-additional">Jumlah biaya tambahan</label>
            <b-input-group class="input-group-merge">
              <input
                id="jumlah-additional"
                :value="formPayload.additional_value"
                type="text"
                class="custom__input pl-2"
                style="width: 80%"
                @input="getRupiah"
                @change="getRupiah"
              />
              <b-input-group-append is-text>
                <div class="button__group">
                  <b-button class="button__is--discount active"> Rp </b-button>
                </div>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label for="catatan-additional">Catatan biaya tambahan</label>
            <b-input-group class="input-group-merge">
              <input
                id="catatan-additional"
                v-model="formPayload.note_value"
                type="text"
                class="custom__input pl-2 w-100"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
    <div class="button__group">
      <b-button
        class="button__cancel"
        @click="$bvModal.hide('modal-additional-input-add')"
      >
        Batal
      </b-button>
      <b-button class="button__save" @click="addAdditional()">
        Simpan
      </b-button>
    </div>
  </b-modal>
</template>

<script>
// import { ValidationProvider } from 'vee-validate'
import {
  BFormInput,
  BRow,
  BCol,
  BModal,
  VBToggle,
  BButton,
  BFormGroup,
  BContainer,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
  preformatFloat,
} from "@/auth/utils";
import Cleave from "cleave.js";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BModal,
    BFormInput,
    BButton,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
    cleave: {
      inserted: (el, binding) => {
        // eslint-disable-next-line no-param-reassign
        el.cleave = new Cleave(el, binding.value || {});
      },
      update: (el) => {
        const event = new Event("input", { bubbles: true });
        setTimeout(() => {
          // eslint-disable-next-line no-param-reassign
          el.value = el.cleave.properties.result;
          el.dispatchEvent(event);
        }, 100);
      },
    },
  },
  props: {
    formData: {
      type: Object,
    },
    pricelist: {
      type: Number,
    },
  },
  data() {
    return {
      money: {
        decimal: "",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false,
      },
      is_ritel: true,
      is_discount: false,
      formPayload: {
        note_value: "",
        additional_value: "",
      },
    };
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
      preformatFloat,
    };
  },
  watch: {
    "$store.state.cashier.additional_fees": function (value) {
      this.formPayload = {
        note_value: value.additional_fee_note,
        additional_value: this.formatRupiah(
          value.additional_fee_amount,
          "Rp. "
        ),
      };
    },
    formData(value) {
      this.formPayload = {
        note_value: value.additional_fee_note,
        additional_value: value.additional_fee_amount
          ? this.formatRupiah(
              value.additional_fee_amount.toString().replace(".", ","),
              "Rp. "
            )
          : "",
      };
    },
  },
  methods: {
    getRupiah() {
      const rupiah = document.getElementById("jumlah-additional");
      rupiah.value = this.formatRupiah(rupiah.value, "Rp. ");
      this.formPayload.additional_value = rupiah.value;
    },
    formatRupiah(angka, prefix) {
      const number_string = angka.replace(/[^,\d]/g, "").toString();
      const split = number_string.split(",");
      const sisa = split[0].length % 3;
      let rupiah = split[0].substr(0, sisa);
      const ribuan = split[0].substr(sisa).match(/\d{3}/gi);

      if (ribuan) {
        const separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }

      rupiah = split[1] != undefined ? `${rupiah},${split[1]}` : rupiah;
      return prefix == undefined ? rupiah : rupiah || "";
    },
    // eslint-disable-next-line consistent-return
    addAdditional() {
      console.log(this.formPayload);
      const additionalValue = parseFloat(
        this.preformatFloat(this.formPayload.additional_value)
      );
      this.$store.commit("stockWarehouse/setAdditionalFees", {
        additional_fee_note: this.formPayload.note_value,
        additional_fee_amount: additionalValue,
      });

      this.$bvModal.hide("modal-additional-input-add");

      // this.formPayload = {
      //   discount_type: 'percent',
      //   additional_value: '',
      // }
      // this.clearItem()
    },
  },
};
</script>

<style lang="scss">
@import "/src/assets/scss/variables/_variables.scss";

#modal-additional-input-add {
  .modal-dialog {
    .modal-content {
      background: #fcfcfc;
      border-radius: 16px;

      .modal-body {
        padding: 0;
        .form-group {
          label {
            font-size: 14px;
            color: $dark;
            font-weight: 600;
          }
          input {
            background: $light--background;
            border: none;
            border-radius: 8px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            height: 52px;
            color: $dark;
            font-weight: 600;
          }
          .input-group {
            .input-group-append {
              .input-group-text {
                border: 1px solid $light--background;
                background-color: $light--background;
                color: $light--description;
                border-radius: 8px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
              }
            }
          }
        }
        .button__group {
          display: flex;
          width: 100%;

          .button__is--discount {
            background: transparent !important;
            border: none !important;
            color: $light--description !important;

            &.active {
              background: #ffffff !important;
              border: none !important;
              color: $dark !important;
            }
          }

          .button__cancel {
            background-color: #ffffff !important;
            border-color: #fff !important;
            color: $dark !important;
            box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
            border-radius: 0px 0px 0px 16px;
            width: 100%;
            height: 72px;
            font-size: 16px;
            font-weight: 800;
          }

          .button__save {
            background-color: #45b6ab !important;
            border-color: #45b6ab !important;
            box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
            border-radius: 0px 0px 16px 0px;
            width: 100%;
            height: 72px;
            font-size: 16px;
            font-weight: 800;
          }
        }
      }
    }
  }
}
</style>
