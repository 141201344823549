<template>
  <b-modal
    id="modal-additional-fee"
    hide-header
    hide-footer
    centered
    no-close-on-backdrop
    title="Vertically Centered"
    ok-only
    ok-title="Accept"
  >
    <div class="p-2 d-flex justify-content-between">
      <div />
      <h1 class="h4 text-dark fw-bold-700 mb-0 size16">
        Masukkan Biaya Tambahan
      </h1>
      <feather-icon
        class="text-dark fw-bold-700"
        size="20"
        icon="XIcon"
        @click="$bvModal.hide('modal-additional-fee')"
      />
    </div>
    <hr class="my-0" />
    <b-container>
      <b-row class="p-1 mb-1">
        <b-col lg="12">
          <b-form-group
            class="custom__form--input"
            label="Masuk Harga Modal"
            :label-for="'checkbox-' + include_number_item"
          >
            <b-form-checkbox
              :id="'checkbox-' + include_number_item"
              v-model="includeStock"
              :name="'checkbox-' + include_number_item"
              value="1"
              unchecked-value="0"
            >
              Iya
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col lg="12" class="px-0">
          <b-card
            class="border-0 p-0 mb-0 w-100"
            style="box-shadow: none"
            no-body
          >
            <div
              class="d-flex align-items-center justify-content-between w-100"
            >
              <div
                class="d-flex align-items-center justify-content-between px-2 py-1 w-100"
                @click="addAdditional"
              >
                <div class="d-flex align-items-center">
                  <div class="rounded__frame align-self-center">
                    <b-img
                      width="20"
                      class="mr-25"
                      :src="require('@/assets/images/icons/discount.svg')"
                    />
                  </div>
                  <h6 class="text-primary font-weight-bolder mb-0">
                    Masukkan biaya tambahan
                  </h6>
                </div>
                <feather-icon
                  class="text-dark font-weight-bolder"
                  size="20"
                  icon="PlusIcon"
                />
              </div>
            </div>
            <b-row class="" v-if="additionalStock.length > 0">
              <b-col cols="12">
                <hr class="mt-0" />
              </b-col>
              <b-col
                v-for="(additional_fee_amount, index) in additionalStock"
                :key="index"
                cols="12"
                class="mb-1"
              >
                <div class="d-flex px-2 flex-column" style="gap: 16px">
                  <h6 class="mb-0 w-50">{{ index + 1 }}. Biaya Tambahan</h6>
                  <div class="d-flex align-items-center w-100">
                    <div class="d-flex flex-column w-100" style="gap: 16px">
                      <div class="w-100 form-control custom__input">
                        {{
                          additional_fee_amount.additional_fee_amount
                            | formatAmount
                        }}
                      </div>
                      <div class="w-100 form-control custom__input">
                        {{ additional_fee_amount.additional_fee_note }}
                      </div>
                    </div>
                    <div
                      class="align-self-center text-right ml-1"
                      role="button"
                      @click="editAdditional(index, additional_fee_amount)"
                    >
                      <feather-icon
                        size="24"
                        icon="EditIcon"
                        class="text-primary fw-bold-800"
                      />
                    </div>
                    <div
                      class="align-self-center text-right ml-1"
                      role="button"
                      @click="deleteAdditional(index)"
                    >
                      <feather-icon
                        size="24"
                        icon="Trash2Icon"
                        class="text-danger fw-bold-800"
                      />
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <div class="button__group">
      <b-button
        class="button__cancel"
        @click="$bvModal.hide('modal-additional-fee')"
      >
        Batal
      </b-button>
      <b-button class="button__save" @click="saveStockAdditional">
        Simpan
      </b-button>
    </div>
    <ModalAddtionalFee
      :pricelist="pricelist"
      :is-stock="true"
      :formData="additional_fee_amount"
    />
  </b-modal>
</template>

<script>
// import { ValidationProvider } from 'vee-validate'
import {
  BRow,
  BCol,
  BModal,
  VBToggle,
  BButton,
  BContainer,
  BImg,
  BCard,
  BFormCheckbox,
  BFormGroup,
} from "bootstrap-vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
  preformatFloat,
  formatRupiah,
} from "@/auth/utils";
import ModalAddtionalFee from "@/components/Stock/Modal/AdditionalInputFee.vue";
import Ripple from "vue-ripple-directive";
import Cleave from "cleave.js";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BModal,
    BButton,
    BImg,
    BCard,
    ModalAddtionalFee,
    BFormCheckbox,
    BFormGroup,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
    cleave: {
      inserted: (el, binding) => {
        // eslint-disable-next-line no-param-reassign
        el.cleave = new Cleave(el, binding.value || {});
      },
      update: (el) => {
        const event = new Event("input", { bubbles: true });
        setTimeout(() => {
          // eslint-disable-next-line no-param-reassign
          el.value = el.cleave.properties.result;
          el.dispatchEvent(event);
        }, 100);
      },
    },
  },
  props: {
    additional_fees: {
      type: Array,
    },
    pricelist: {
      type: Number,
    },
    include_fee: {
      type: Number,
    },
    summaryDiscount: {
      type: Function,
    },
    include_number_item: {
      type: Number,
    },
  },
  data() {
    return {
      money: {
        decimal: "",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false,
      },
      is_ritel: true,
      is_discount: false,
      formPayload: {
        additional_fee_note: "",
        value: 0,
      },
      additionalStock: [],
      includeStock: 0,
      includeNumber: 0,
      additional_fee_amount: {},
    };
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
      preformatFloat,
      formatRupiah,
    };
  },
  watch: {
    "$store.state.stockWarehouse.additional_fees": {
      handler(value) {
        console.log(value);
        if (this.$store.state.stockWarehouse.indexAddtionalFee !== false) {
          this.additionalStock.splice(
            this.$store.state.stockWarehouse.indexAddtionalFee,
            1
          );
          this.additionalStock.splice(
            this.$store.state.stockWarehouse.indexAddtionalFee,
            0,
            {
              additional_fee_note: value?.additional_fee_note,
              additional_fee_amount: value?.additional_fee_amount,
            }
          );
          // this.summaryDiscount()
        } else {
          this.additionalStock.push({
            additional_fee_note: value?.additional_fee_note,
            additional_fee_amount: value?.additional_fee_amount,
          });
          // this.summaryDiscount()
        }
      },
      deep: true,
    },
    additional_fees: {
      handler(value) {
        this.additionalStock = value;
      },
      deep: true,
    },
    include_fee: {
      handler(value) {
        this.includeStock = value;
      },
      deep: true,
    },
  },
  methods: {
    editAdditional(index, additional_fee_amount) {
      this.$store.commit("stockWarehouse/setIndexAdditionalFee", index);
      this.additional_fee_amount = additional_fee_amount;
      this.$store.commit("stockWarehouse/setIsAddDiscount", true);
      this.$bvModal.show("modal-additional-input-add");
    },
    deleteAdditional(index) {
      this.additional_fees.splice(index, 1);
    },
    addAdditional() {
      this.$store.commit("stockWarehouse/setIndexAdditionalFee", false);
      this.additional_fee_amount = {
        additional_fee_note: "",
        additional_fee_amount: 0,
      };
      this.$store.commit("stockWarehouse/setIsAddDiscount", true);
      this.$bvModal.show("modal-additional-input-add");
    },
    saveStockAdditional() {
      this.$emit("additional_stocks", this.additionalStock);
      this.$emit("include_stock", this.includeStock, this.include_number_item);
      // this.includeStock = 0;
      this.$bvModal.hide("modal-additional-fee");
    },
  },
};
</script>

<style lang="scss">
@import "/src/assets/scss/variables/_variables.scss";

#modal-additional-fee {
  .modal-dialog {
    .modal-content {
      background: #fcfcfc;
      border-radius: 16px;

      .modal-body {
        padding: 0;
        .form-group {
          label {
            font-size: 14px;
            color: $dark;
            font-weight: 600;
          }
          input {
            background: $light--background;
            border: none;
            border-radius: 8px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            height: 52px;
            color: $dark;
            font-weight: 600;
          }
          .input-group {
            .input-group-append {
              .input-group-text {
                border: 1px solid $light--background;
                background-color: $light--background;
                color: $light--description;
                border-radius: 8px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
              }
            }
          }
        }
        .button__group {
          display: flex;
          width: 100%;

          .button__is--additional_fee_amount {
            background: transparent !important;
            border: none !important;
            color: $light--description !important;

            &.active {
              background: #ffffff !important;
              border: none !important;
              color: $dark !important;
            }
          }

          .button__cancel {
            background-color: #ffffff !important;
            border-color: #fff !important;
            color: $dark !important;
            box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
            border-radius: 0px 0px 0px 16px;
            width: 100%;
            height: 72px;
            font-size: 16px;
            font-weight: 800;
          }

          .button__save {
            background-color: #45b6ab !important;
            border-color: #45b6ab !important;
            box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
            border-radius: 0px 0px 16px 0px;
            width: 100%;
            height: 72px;
            font-size: 16px;
            font-weight: 800;
          }
        }
      }
    }
  }
}
</style>
