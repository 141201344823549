var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"custom__form--input"},[_c('label',{attrs:{"for":"v-date-in"}},[_vm._v("Tanggal Masuk "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{staticClass:"custom__input",staticStyle:{"padding-top":"1rem"},attrs:{"id":"v-date-in","type":"date","placeholder":"dd/mm/yyy","disabled":_vm.$route.query.q !== 'warehouse'
              ? !_vm.checkPermission('update stok supplier') && _vm.isEdit
              : !_vm.checkPermission('update stok gudang') && _vm.isEdit},model:{value:(_vm.formPayload.date),callback:function ($$v) {_vm.$set(_vm.formPayload, "date", $$v)},expression:"formPayload.date"}})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"custom__form--input"},[_c('label',{attrs:{"for":"v-supplier"}},[_vm._v("Pilih Pemasok "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('v-select',{attrs:{"label":"name","options":_vm.options,"placeholder":"Pilih Pemasok","disabled":(_vm.$route.params.id && _vm.utang && _vm.utang.paid > 0) ||
            _vm.$route.query.q !== 'warehouse'
              ? !_vm.checkPermission('update stok supplier') && _vm.isEdit
              : !_vm.checkPermission('update stok gudang') && _vm.isEdit},on:{"search":_vm.onSearch,"input":function($event){return _vm.getSupplierValue($event)}},model:{value:(_vm.formPayload.selectedSupplier),callback:function ($$v) {_vm.$set(_vm.formPayload, "selectedSupplier", $$v)},expression:"formPayload.selectedSupplier"}})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"custom__form--input"},[_c('label',{attrs:{"for":"v-nomor-po"}},[_vm._v("Nomor PO/SO "),(_vm.$route.query.warehouse === '0')?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),_c('b-form-input',{staticClass:"custom__input",attrs:{"id":"v-nomor-po","type":"text","placeholder":"x-xxxx-xxxx","disabled":_vm.$route.query.q !== 'warehouse'
              ? !_vm.checkPermission('update stok supplier') && _vm.isEdit
              : !_vm.checkPermission('update stok gudang') && _vm.isEdit},model:{value:(_vm.formPayload.po_so_id),callback:function ($$v) {_vm.$set(_vm.formPayload, "po_so_id", $$v)},expression:"formPayload.po_so_id"}})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('div',[_c('TransitionGroup',{attrs:{"name":"fade"}},_vm._l((_vm.formPayload.delivery_numbers),function(item,index){return _c('b-row',{key:index,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-col',{staticClass:"mb-50",attrs:{"cols":"12","lg":_vm.$route.query.warehouse === '0' ? '9' : '12',"md":_vm.$route.query.warehouse === '0' ? '9' : '12'}},[_c('b-form-group',{staticClass:"custom__form--input"},[_c('label',{attrs:{"for":("v-delivery-number-" + index)}},[_vm._v("Nomor Surat Jalan "+_vm._s(_vm.$route.query.warehouse === "1" ? "" : index + 1)+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{staticClass:"custom__input",attrs:{"id":("v-delivery-number-" + index),"type":"text","placeholder":"x-xxxx-xxxx","disabled":_vm.$route.query.q !== 'warehouse'
                      ? !_vm.checkPermission('update stok supplier') && _vm.isEdit
                      : !_vm.checkPermission('update stok gudang') && _vm.isEdit},model:{value:(
                    _vm.formPayload.delivery_numbers[index].delivery_number_note
                  ),callback:function ($$v) {_vm.$set(_vm.formPayload.delivery_numbers[index], "delivery_number_note", $$v)},expression:"\n                    formPayload.delivery_numbers[index].delivery_number_note\n                  "}})],1)],1),(_vm.$route.query.warehouse === '0')?_c('b-col',{staticClass:"align-self-center",attrs:{"lg":"3","md":"3"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v("Delete")])],1)],1):_vm._e()],1)}),1),(_vm.$route.query.warehouse === '0')?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":_vm.repeateAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Tambah Surat Jalan")])],1):_vm._e()],1)]),(_vm.$route.query.warehouse === '0' && _vm.$route.query.q !== 'warehouse')?_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"custom__form--input"},[_c('label',[_vm._v("Jenis Pengiriman "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-radio',{staticClass:"my-1",attrs:{"name":"some-radios","value":"Franco"},model:{value:(_vm.formPayload.shipping),callback:function ($$v) {_vm.$set(_vm.formPayload, "shipping", $$v)},expression:"formPayload.shipping"}},[_vm._v(" Franco ")]),_c('b-form-radio',{attrs:{"name":"some-radios","value":"Loco"},model:{value:(_vm.formPayload.shipping),callback:function ($$v) {_vm.$set(_vm.formPayload, "shipping", $$v)},expression:"formPayload.shipping"}},[_vm._v(" Loco ")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }