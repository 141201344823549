<template>
  <div>
    <div
      class="form-purchase"
      :class="!$route.params.id ? 'px-2' : 'px-3 px-md-0'"
    >
      <div class="table-responsive">
        <table>
          <thead>
            <tr>
              <th>Nama produk <span class="text-danger">*</span></th>
              <th>Qty <span class="text-danger">*</span></th>
              <th>Pricelist Rp <span class="text-danger">*</span></th>
              <th>Sudah PPN</th>
              <!-- <th>Diskon Satuan</th> -->
              <th>Total Diskon</th>
              <th>Total Biaya Tambahan</th>
              <!-- <th>Masuk Harga Modal</th> -->
              <th>Pilih Lokasi SKU <span class="text-danger">*</span></th>
              <th>Total Rp</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in formProducts.items"
              :id="item.id"
              :key="item.id"
            >
              <td class="pr-1 pb-25">
                <img
                  v-if="formProducts.items[index].is_bonus == 1"
                  :style="`position: absolute;z-index: 1;left: ${
                    $route.params.id ? '0' : '11px'
                  };margin-top:17px;`"
                  :src="require('@/assets/images/icons/bonus.svg')"
                />
                <!-- <span
                    class="d-flex align-items-center text-dark fw-bold-600 pl-1"
                    :class="{
                      'disabled-span':
                        $route.query.q !== 'warehouse'
                          ? !checkPermission('update stok supplier') && isEdit
                          : !checkPermission('update stok gudang') && isEdit,
                    }"
                    style="
                      background: #eff1f5;
                      border: 1px solid #e4e5ec;
                      border-radius: 8px;
                      height: 52px;
                      width: 12rem;
                      overflow-x: scroll;
                    "
                    role="button"
                    @click="setProducts(index)"
                  >
                    {{
                      formProducts.items[index].product_uuid.title ||
                      "Pilih Produk"
                    }}
                  </span> -->
                <div class="h-100 position-relative">
                  <div
                    class="w-100 h-100 position-absolute"
                    style="left: 0; right: 0; margin: 0 auto; z-index: 50"
                    @click="setProducts(index)"
                  ></div>
                  <div
                    class="p-1 fw-bold-600 w-100"
                    style="
                      border: 1px solid #e4e5ec;
                      border-radius: 8px;
                      background: #eff1f5;
                    "
                    contenteditable
                  >
                    {{
                      formProducts.items[index].product_uuid.title ||
                      "Pilih Produk"
                    }}
                  </div>
                  <!-- <textarea
                    :ref="'choose-product-' + index"
                    v-model="formProducts.items[index].product_uuid.title"
                    class="custom__input p-1 fw-bold-600 w-100"
                    :id="'choose-product-' + index"
                    placeholeder="Pilih Produk"
                    disabled
                  /> -->
                </div>
              </td>
              <td class="pr-1 pb-25">
                <b-form-group
                  class="custom__form--input mb-0"
                  label=""
                  label-for="quantity"
                >
                  <input
                    :id="`qty-${index}`"
                    :value="formProducts.items[index].qty"
                    type="text"
                    class="custom__input pl-1"
                    style="width: 4rem"
                    @input="getQty(index)"
                    @change="getQty(index)"
                    :disabled="
                      $route.query.q !== 'warehouse'
                        ? !checkPermission('update stok supplier') && isEdit
                        : !checkPermission('update stok gudang') && isEdit
                    "
                  />
                  <!-- <money
                    v-model="formProducts.items[index].qty"
                    v-bind="money"
                    class="custom__input pl-1"
                    style="width: 4rem;"
                  /> -->
                </b-form-group>
              </td>
              <td class="pr-1 pb-25">
                <b-form-group
                  class="custom__form--input mb-0"
                  label=""
                  label-for="cost"
                >
                  <input
                    :id="`rupiah-${index}`"
                    :value="formProducts.items[index].price"
                    type="text"
                    class="custom__input pl-2"
                    style="width: 9rem"
                    @input="getRupiah(index)"
                    @change="getRupiah(index)"
                    :disabled="
                      $route.query.q !== 'warehouse'
                        ? !checkPermission('update stok supplier') && isEdit
                        : !checkPermission('update stok gudang') && isEdit
                    "
                  />
                </b-form-group>
              </td>
              <td class="pr-1 pb-25">
                <b-form-group
                  class="custom__form--input mb-0"
                  label=""
                  label-for="ppn"
                >
                  <b-form-select
                    id="ppn"
                    v-model="formProducts.items[index].include_ppn"
                    :options="optionPPN"
                    style="width: 6rem"
                    :disabled="
                      $route.query.q !== 'warehouse'
                        ? !checkPermission('update stok supplier') && isEdit
                        : !checkPermission('update stok gudang') && isEdit
                    "
                  />
                </b-form-group>
              </td>
              <td class="pr-1 pb-25">
                <b-form-group
                  class="custom__form--input mb-0"
                  label=""
                  label-for="disc-rp"
                >
                  <span
                    class="d-flex align-items-center text-dark fw-bold-600 pl-1"
                    style="
                      background: #eff1f5;
                      border: 1px solid #e4e5ec;
                      border-radius: 8px;
                      height: 52px;
                      width: 9rem;
                    "
                    >{{ totalDiscount[index] | formatAmount }}</span
                  >
                </b-form-group>
              </td>
              <td class="pr-1 pb-25">
                <b-form-group
                  class="custom__form--input mb-0"
                  label=""
                  label-for="disc-rp"
                >
                  <span
                    class="d-flex align-items-center text-dark fw-bold-600 pl-1"
                    style="
                      background: #eff1f5;
                      border: 1px solid #e4e5ec;
                      border-radius: 8px;
                      height: 52px;
                      width: 9rem;
                    "
                    >{{ totalAdditional[index] | formatAmount }}</span
                  >
                </b-form-group>
              </td>
              <!-- <td class="pr-1 pb-25">
                <b-form-checkbox
                  :id="`checkbox-${index}`"
                  v-model="formProducts.items[index].include_fee_in_avg_price"
                  :name="`checkbox-${index}`"
                  value="1"
                  unchecked-value="0"
                >
                  Iya
                </b-form-checkbox>
              </td> -->
              <td class="pr-1 pb-25">
                <b-form-group
                  class="custom__form--input mb-0"
                  label=""
                  label-for="warehouse"
                >
                  <b-form-select
                    id="warehouse"
                    v-model="formProducts.items[index].warehouse_uuid"
                    style="width: 9rem"
                    :disabled="
                      $route.query.q !== 'warehouse'
                        ? !checkPermission('update stok supplier') && isEdit
                        : !checkPermission('update stok gudang') && isEdit
                    "
                  >
                    <b-form-select-option
                      v-for="warehouse in warehouses[index]"
                      :key="warehouse.uuid"
                      :value="warehouse.uuid"
                      >{{ warehouse.name }}</b-form-select-option
                    >
                  </b-form-select>
                </b-form-group>
              </td>
              <td class="pr-1 pb-25">
                <div class="d-flex">
                  <span
                    class="d-flex align-items-center text-dark fw-bold-600 pl-1"
                    style="
                      background: #eff1f5;
                      border: 1px solid #e4e5ec;
                      border-radius: 8px;
                      height: 52px;
                      width: 12rem;
                    "
                    >{{ total[index] | formatAmount }}</span
                  >

                  <b-dropdown
                    id="dropdown-dropleft"
                    class="d-flex p-0"
                    dropleft
                    :disabled="
                      $route.query.q !== 'warehouse'
                        ? !checkPermission('update stok supplier') && isEdit
                        : !checkPermission('update stok gudang') && isEdit
                    "
                  >
                    <template #button-content class="btn-white text-center">
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle"
                      />
                    </template>
                    <b-dropdown-item
                      v-if="formProducts.items[index].is_bonus == 0"
                      @click="makeItemBonus(index)"
                    >
                      <span> Jadikan sebagai bonus </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-else
                      @click="formProducts.items[index].is_bonus = 0"
                    >
                      <span> Hapus sebagai bonus </span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="removeItem(index)">
                      <span> Hapus produk </span>
                    </b-dropdown-item>
                  </b-dropdown>
                  <b-dropdown
                    id="dropdown-dropleft"
                    class="d-flex p-0"
                    dropleft
                    :disabled="
                      $route.query.q !== 'warehouse'
                        ? !checkPermission('update stok supplier') && isEdit
                        : !checkPermission('update stok gudang') && isEdit
                    "
                  >
                    <template #button-content class="btn-white text-center">
                      <feather-icon
                        icon="PlusIcon"
                        size="16"
                        class="align-middle"
                      />
                    </template>
                    <b-dropdown-item
                      @click="
                        addStockDiscount(
                          formProducts.items[index].discounts,
                          index,
                          formProducts.items[index].price
                        )
                      "
                      :disabled="
                        !formProducts.items[index].product_uuid ||
                        ($route.query.q !== 'warehouse'
                          ? !checkPermission('update stok supplier') && isEdit
                          : !checkPermission('update stok gudang') && isEdit)
                      "
                    >
                      <span> Tambah/Ubah Diskon </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="
                        addStockFeeAmount(
                          formProducts.items[index].additional_fees,
                          index,
                          formProducts.items[index].price,
                          formProducts.items[index].include_fee_in_avg_price
                        )
                      "
                      :disabled="
                        !formProducts.items[index].product_uuid ||
                        ($route.query.q !== 'warehouse'
                          ? !checkPermission('update stok supplier') && isEdit
                          : !checkPermission('update stok gudang') && isEdit)
                      "
                    >
                      <span> Tambah/Ubah Biaya Tambahan </span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </td>
            </tr>
            <!-- add more rows here -->
          </tbody>
        </table>
      </div>
      <b-button
        v-if="!$route.params.id"
        class="btn-block mb-lg-4 mb-md-1"
        variant="outline-dark border-white btn__add--product"
        @click="repeateAgain"
      >
        <feather-icon icon="PlusIcon" class="mr-25" />
        <span>Tambah Produk</span>
      </b-button>
      <!-- <div
        v-if="!$route.params.id"
        class="footer__purchase d-flex align-items-center p-lg-2 p-md-1"
      >
        <b-row class="mx-0 w-100">
          <b-col lg="6" md="5" class="border-right">
            <div class="d-flex mb-1">
              <b-img
                :src="require('/src/assets/images/icons/categories.svg')"
                alt="biaya-tambahan"
              />
              <h6 class="text-dark mb-0 fw-bold-700 ml-1">Biaya Tambahan</h6>
            </div>
            <b-row>
              <b-col lg="4" md="5" class="pr-0 custom__form--input">
                <money
                  v-model="formProducts.additional_fee"
                  v-bind="money"
                  class="custom__input pl-1 w-100"
                  placeholder="Rp0,00"
                />
              </b-col>
              <b-col lg="8" md="7" class="custom__form--input">
                <b-form-input
                  v-model="formProducts.additional_fee_note"
                  class="custom__input w-100"
                  type="text"
                  placeholder="Detil biaya tambahan"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="6" md="7" class="custom__form--input">
            <div class="d-flex mb-1">
              <b-img
                :src="require('/src/assets/images/icons/pencil.svg')"
                alt="catatan-penting"
              />
              <h6 class="text-dark mb-0 fw-bold-700 ml-1">Catatan Penting</h6>
            </div>
            <b-form-input
              v-model="formProducts.note"
              class="custom__input"
              type="text"
              placeholder="Tulis catatan penting disini (opsional)"
            />
          </b-col>
        </b-row>
      </div> -->
      <ModalStockDiscount
        :pricelist="price"
        :discounts="discounts"
        @discount_stocks="getDiscountStocks"
      />
      <ModalAdditional
        :pricelist="price"
        :formProducts="formProducts"
        :additional_fees="additional_fees"
        :include_fee="include_fee"
        :include_number_item="include_number"
        @additional_stocks="getAdditionalStocks"
        @include_stock="getIncludeFee"
      />
      <b-modal
        id="modal-add-product-is-null"
        hide-header
        hide-footer
        centered
        title="Vertically Centered"
        ok-only
        ok-title="Accept"
      >
        <div class="p-2 d-flex justify-content-center text-center">
          <h4 class="text-dark font-bold-700 size16 mb-0">Tambah Produk</h4>
        </div>
        <b-container class="mb-md-3 mb-2">
          <b-row class="p-2">
            <b-col cols="12">
              <div class="mb-2 custom__form--input">
                <label>Nama Produk <span class="text-danger">*</span></label>
                <b-form-input
                  id="search-product"
                  v-model="formPayloadCheck.alias_name"
                  class="focus-0 custom__input"
                  type="text"
                  placeholder="Nama Produk"
                />
              </div>
              <div class="mb-2">
                <label for="v-satuan" class="text-dark fw-bold-600 size14"
                  >Satuan <span class="text-danger">*</span></label
                >
                <v-select
                  v-model="formPayloadCheck.uom_uuid"
                  placeholder="Pilih Satuan"
                  label="name"
                  taggable
                  :options="optionUom"
                  @search="onSearchUom"
                />
              </div>
              <div class="mb-2 custom__form--input">
                <label for="harga-beli"
                  >Harga Jual Standard <span class="text-danger">*</span></label
                >
                <input
                  id="harga-jual-standard"
                  class="focus-0 custom__input pl-1 w-100"
                  type="text"
                  v-model="formPayloadCheck.standard_price"
                  @input="getRupiahStandard"
                  @change="getRupiahStandard"
                />
              </div>
              <div class="mb-2 custom__form--input">
                <label for="harga-beli"
                  >Harga Jual Minimum <span class="text-danger">*</span></label
                >
                <input
                  id="harga-jual-minimum"
                  class="focus-0 custom__input pl-1 w-100"
                  type="text"
                  v-model="formPayloadCheck.minimum_price"
                  @input="getRupiahMinimum"
                  @change="getRupiahMinimum"
                />
              </div>
              <div class="mb-2 custom__form--input">
                <label for="harga-beli"
                  >Harga Beli <span class="text-danger">*</span></label
                >
                <input
                  id="harga-beli"
                  class="focus-0 custom__input pl-1 w-100"
                  type="text"
                  v-model="formPayloadCheck.buying_price"
                  @input="getRupiahBeli"
                  @change="getRupiahBeli"
                />
              </div>
              <div class="mb-2 custom__form--input">
                <label class="mb-1">
                  Pilih Lokasi SKU <span class="text-danger">*</span>
                </label>
                <div v-for="(warehouse, index) in warehouseData" :key="index">
                  <div class="d-flex justify-content-between mb-1">
                    <h6 class="text-dark fw-bold-700">
                      {{ warehouse.name }}
                    </h6>
                    <b-form-checkbox
                      :id="`yes-${index}`"
                      :ref="`warehouseId${index}`"
                      v-model="
                        formPayloadCheck.initial_stocks[index].warehouse_uuid
                      "
                      :value="warehouse.uuid"
                      class="custom-control-success"
                    >
                      Ya
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
        <div class="button__group">
          <b-button
            class="button__cancel"
            @click="$bvModal.hide('modal-add-product-is-null')"
          >
            Batal
          </b-button>
          <b-button
            class="button__save d-flex align-items-center justify-content-center"
            :disabled="
              formPayloadCheck.initial_stocks.every(
                (e) => e.warehouse_uuid == ''
              ) ||
              formPayloadCheck.alias_name == '' ||
              formPayloadCheck.uom_uuid == ''
            "
            @click.prevent="createItem"
          >
            <div v-if="isLoadingProduct">
              <b-spinner small variant="primary" />
            </div>
            Simpan
          </b-button>
        </div>
      </b-modal>
    </div>
    <div class="px-3 border-top">
      <div class="row border-bottom py-3">
        <b-col cols="12" md="6" lg="5">
          <h6 class="size14 fw-bold-700">Ringkasan Biaya Tambahan</h6>
        </b-col>
        <b-col cols="12" md="6" lg="7">
          <div
            class="mb-2 d-flex align-items-center"
            style="gap: 10rem"
            v-for="(item, index) in formProducts.items"
            :key="index"
            v-if="item.additional_fees.length !== 0"
          >
            <h3 class="size16 fw-bold-700">
              {{ item.product_uuid.title }}
            </h3>
            <!-- Check if additional_fees exists and is empty -->
            <!-- <p
              v-if="!item.additional_fees || item.additional_fees.length === 0"
            >
              Tidak ada biaya tambahan
            </p> -->
            <ul
              v-for="(additional, idx) in item.additional_fees"
              :key="idx"
              class="p-0"
            >
              <li>
                {{ additional.additional_fee_note | formatAmount }}
              </li>
              <li>
                {{ additional.additional_fee_amount | formatAmount }}
              </li>
            </ul>
          </div>
        </b-col>
      </div>
      <div class="row border-bottom py-3">
        <b-col cols="12" md="6" lg="5">
          <h6 class="size14 fw-bold-700">Diskon</h6>
        </b-col>
        <b-col cols="12" md="6" lg="7">
          <b-row>
            <b-col cols="12" md="3" class="mb-2 custom__form--input">
              <label for="v-jenis-diskon">Jenis Diskon </label>
              <b-form-group>
                <b-form-select
                  id="v-jenis-diskon"
                  v-model="formProducts.per_surat_jalan_type"
                  :options="discountType"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4" class="mb-2 custom__form--input">
              <label for="v-nominal-diskon">Nominal Diskon </label>
              <b-form-group>
                <input
                  id="v-nominal-diskon"
                  v-model="nominalDiscount"
                  type="text"
                  class="custom__input w-100 pl-1"
                  @input="getRupiahSuratJalan"
                  @change="getRupiahSuratJalan"
                />
                <!-- :value="formProducts.per_surat_jalan_discount" -->
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4" class="mb-2 custom__form--input">
              <label for="v-total-diskon">Total Diskon </label>
              <b-form-group
                class="custom__form--input mb-0"
                label=""
                label-for="disc-rp"
              >
                <span
                  class="d-flex align-items-center text-dark fw-bold-600 pl-1"
                  style="
                    background: #eff1f5;
                    border: 1px solid #e4e5ec;
                    border-radius: 8px;
                    height: 52px;
                  "
                  >{{ totalDiscountJalan | formatAmount }}</span
                >
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </div>
      <div class="row py-3">
        <b-col cols="12" md="6" lg="5">
          <h6 class="size14 fw-bold-700">Total Harga</h6>
        </b-col>
        <b-col cols="12" md="6" lg="7">
          <div class="border-bottom">
            <div class="d-flex align-items-center justify-content-between mb-1">
              <p class="mb-0">Subtotal barang</p>
              <p class="mb-0">{{ totalPayment | formatAmount }}</p>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-1">
              <p class="mb-0">Diskon</p>
              <p class="mb-0">{{ totalDiscountJalan | formatAmount }}</p>
            </div>
          </div>
          <div class="pt-2">
            <div class="d-flex align-items-center justify-content-between mb-1">
              <p class="mb-0 fw-bold-700">Total Harga</p>
              <p class="mb-0 fw-bold-700">
                {{ grandTotalDiscountJalan | formatAmount }}
              </p>
            </div>
          </div>
        </b-col>
      </div>
    </div>
    <AddProduct
      ref="productChild"
      @selectedProduct="getSelectedProduct"
      v-if="$route.name == 'stock.add.index'"
    />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from "lodash";
import { mapState } from "vuex";
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BDropdown,
  BDropdownItem,
  BImg,
  BFormSelect,
  BFormSelectOption,
  BModal,
  BContainer,
  BFormCheckbox,
  BSpinner,
} from "bootstrap-vue";
import Cleave from "cleave.js";
import { heightTransition } from "@core/mixins/ui/transition";
import ModalStockDiscount from "@/components/Stock/Modal/ModalDiscount.vue";
import ModalAdditional from "@/components/Stock/Modal/ModalAdditionalFee.vue";
import AddProduct from "@/components/Stock/Modal/AddProduct.vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
  preformatFloat,
} from "@/auth/utils";
import vSelect from "vue-select";

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
    vSelect,
    BImg,
    BFormSelect,
    ModalStockDiscount,
    ModalAdditional,
    BModal,
    BContainer,
    BFormCheckbox,
    BSpinner,
    AddProduct,
    BFormSelectOption,
  },
  setup() {
    return {
      preformatFloat,
      checkPermission,
      successNotification,
      errorNotification,
    };
  },
  directives: {
    cleave: {
      inserted: (el, binding) => {
        // eslint-disable-next-line no-param-reassign
        el.cleave = new Cleave(el, binding.value || {});
      },
      update: (el) => {
        const event = new Event("input", { bubbles: true });
        setTimeout(() => {
          // eslint-disable-next-line no-param-reassign
          el.value = el.cleave.properties.result;
          el.dispatchEvent(event);
        }, 100);
      },
    },
  },
  mixins: [heightTransition],
  props: {
    isEdit: {
      type: Boolean,
    },
    warehouseList: {
      type: Array,
    },
    nominalDiscountEdit: {
      type: Number,
    },
  },
  data() {
    return {
      price: 0,
      indexProduct: false,
      hidePriceList: false,
      money: {
        decimal: "",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false,
      },
      formPayloadCheck: {
        product_type: "parent",
        alias_name: "",
        uom_uuid: "",
        buying_price: "",
        minimum_price: "",
        standard_price: "",
        initial_stocks: [
          {
            warehouse_uuid: "",
          },
        ],
      },
      formProducts: {
        items: [
          {
            product_uuid: "",
            qty: "",
            price: "",
            include_ppn: 0,
            include_fee_in_avg_price: 0,
            discounts: [],
            additional_fees: [],
            is_bonus: 0,
            warehouse_uuid: "",
          },
        ],
        per_surat_jalan_type: "fixed",
        per_surat_jalan_discount: 0,
        additional_fee: 0,
        additional_fee_note: "",
        note: "",
      },
      totalDiscount: [0],
      totalAdditional: [0],
      discounts: [],
      additional_fees: [],
      include_fee: 0,
      include_number: 0,
      // additional_fee_amount: [],
      total: [0],
      count: [0],
      nextTodoId: 2,
      selected: null,
      selectedPPN: "",
      optionsProduct: [],
      optionPPN: [
        { text: "Ya", value: 1 },
        { text: "Tidak", value: 0 },
      ],
      isLoadingProduct: false,
      warehouses: [],
      warehouseData: [],
      optionUom: [],
      discountType: [
        {
          value: "fixed",
          text: "Nominal (RP)",
        },
        {
          value: "percent",
          text: "Persen (%)",
        },
      ],
      nominalDiscount: 0,
      totalDiscountJalan: 0,
      totalPayment: 0,
      grandTotalDiscountJalan: 0,
      warehouseListDetail: [],
    };
  },
  computed: {
    ...mapState({
      formProducts: (state) => state.stockWarehouse.formProducts,
    }),
  },
  watch: {
    nominalDiscount: function (value) {
      this.calculateTotalDiscountJalan();
    },
    "formProducts.per_surat_jalan_type": function () {
      this.calculateTotalDiscountJalan();
    },
    "formProducts.additional_fee": function (value) {
      this.summaryDiscount();
    },
    "formPayloadCheck.uom_uuid": function (value) {
      if (value?.label) {
        this.getUomDetail(value.label);
      }
    },
    warehouseList: {
      handler(value) {
        this.warehouses = value.map((item) => item.product.warehouses);
      },
      immediate: true,
    },
    nominalDiscountEdit: {
      handler(value) {
        this.nominalDiscount = value;
      },
    },
    formProducts: {
      handler(value) {
        this.$store.commit("stockWarehouse/setFormProducts", value);
      },
      deep: true,
    },
    "$store.state.stockWarehouse.formProducts": {
      handler(value) {
        this.formProducts = value;
      },
      deep: true,
    },
    "formProducts.items": {
      handler() {
        this.summaryAdditional();
        this.summaryDiscount();
        // this.summaryTotal();
        this.calculateTotalDiscountJalan();
      },
      deep: true,
    },
  },
  mounted() {
    this.getUom();
    // this.initTrHeight()
    // window.addEventListener('resize', this.initTrHeight)
    // setTimeout(() => {
    //   for (let index = 0; index < this.formProducts.items.length; index++) {
    //     const element = this.formProducts.items[index];
    //     document.getElementById(`rupiah-${index}`).value = this.formatRupiah(document.getElementById(`rupiah-${index}`).value.toString().replace('.', ','), 'Rp. ')
    //     document.getElementById(`qty-${index}`).value = this.formatRupiah(document.getElementById(`qty-${index}`).value.toString().replace('.', ','), 'Rp. ')
    //     this.formProducts.items[index].price = document.getElementById(`rupiah-${index}`).value
    //     this.formProducts.items[index].qty = document.getElementById(`qty-${index}`).value
    //     this.summaryDiscount()
    //   }
    // }, 2000)
  },
  created() {
    // this.getData()
    this.getWarehouse();
    this.initializeWarehouses();
  },
  destroyed() {
    // window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    initializeWarehouses() {
      // Initialize the warehouses array based on formProducts.items
      this.warehouses = this.warehouseListDetail.items.map(
        (item) => item.product.warehouses
      );
    },
    calculateTotalDiscountJalan() {
      // if (this.$route.params !== null) {
      //   this.nominalDiscount = this.nominalDiscount.toLocaleString('id-ID');
      // }
      this.totalPayment = parseFloat(
        localStorage.getItem("cash_total_payment") || 0
      );
      let discountValue = 0;
      if (this.formProducts.per_surat_jalan_type === "fixed") {
        this.totalDiscountJalan = 0;
        if (this.nominalDiscount !== 0) {
          discountValue = Number(this.nominalDiscount.split(".").join("")) || 0;
        }
        this.totalDiscountJalan = discountValue;
        console.log(this.totalDiscountJalan, "fixed");
        this.formProducts.per_surat_jalan_discount = discountValue;
      }
      if (this.formProducts.per_surat_jalan_type === "percent") {
        this.nominalDiscount = Number(this.nominalDiscount.split(".").join(""));
        if (this.nominalDiscount > 100) {
          this.$swal({
            icon: "error",
            title: "Oops!",
            text: "Maksimal diskon 100%",
          });
          return (
            (this.nominalDiscount = 0),
            (this.totalDiscountJalan = 0),
            (this.grandTotalDiscountJalan = this.totalPayment)
          );
        }
        if (this.nominalDiscount <= 100) {
          this.totalDiscountJalan = 0;
          discountValue = this.nominalDiscount || 0;
          discountValue = (discountValue / 100) * this.totalPayment;
          this.totalDiscountJalan = discountValue;
          this.formProducts.per_surat_jalan_discount = this.nominalDiscount;
        }
      }
      if (this.totalDiscountJalan !== 0) {
        let discountJalan = this.totalDiscountJalan;
        this.grandTotalDiscountJalan = this.totalPayment - discountJalan;
        this.$store.commit(
          "stockWarehouse/setTotalProduct",
          this.grandTotalDiscountJalan
        );
      } else {
        this.grandTotalDiscountJalan = this.totalPayment;
        this.$store.commit("stockWarehouse/setTotalProduct", this.totalPayment);
      }
    },
    getRupiahSuratJalan() {
      const rupiah = document.getElementById("v-nominal-diskon");
      rupiah.value = this.formatRupiah(rupiah.value.toString(), "Rp. ");
      this.nominalDiscount = rupiah.value;
    },
    setProducts(index) {
      this.indexProduct = index;
      localStorage.setItem("indexProduct", index);
      this.$bvModal.show("modal-add-product");
    },
    // getWarehouseData(index) {
    //   console.log(this.formProducts.items[index].product_uuid.warehouse);
    //   this.warehouses[index] = this.formProducts.items[index].product_uuid.warehouse;
    // },
    getSelectedProduct(product) {
      this.warehouses[this.indexProduct] = product.warehouses;
      this.formProducts.items[this.indexProduct].product_uuid = {
        label: product.uuid,
        title: product.alias_name,
        warehouse: product.warehouses,
      };
      const latest_incoming_stock_supplier_price =
        product.latest_incoming_stock_supplier_price
          ? product.latest_incoming_stock_supplier_price.toString()
          : "0";
      const latest_incoming_stock_warehouse_price =
        product.latest_incoming_stock_warehouse_price
          ? product.latest_incoming_stock_warehouse_price.toString()
          : "0";
      this.formProducts.items[this.indexProduct].price =
        this.$route.query.warehouse === "1"
          ? this.formatRupiah(latest_incoming_stock_warehouse_price, "")
          : this.formatRupiah(latest_incoming_stock_supplier_price, "");

      this.getSugestion(product, this.indexProduct);
    },
    getSugestion(product, index) {
      const supplierId = localStorage.getItem("supplierValue");
      const productId = product.formatted_id.slice(1);
      this.$http
        .get("/stocks/suggestion-product-prices", {
          params: {
            type: "warehouse",
            supplier_id: supplierId,
            product_id: productId,
          },
        })
        .then((res) => {
          const price = res.data.data.price; // Adjust based on the actual key in the response
          this.formProducts.items[index].price = this.formatRupiah(
            price.toString(),
            ""
          );
        });
    },
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    },
    async getItemsDetail(id) {
      await this.$store
        .dispatch("cashier/getData", {
          uuid: id || "",
          params: "",
        })
        .then((result) => {
          this.item = result.data.data.name;
          setTimeout(() => {
            this.optionsProduct.unshift({
              label: result.data.data.uuid,
              title: result.data.data.name,
            });
            const option = this.getUniqueListBy(this.optionsProduct, "label");
            this.optionsProduct = option;
          }, 500);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    onSearchItem(search, loading) {
      if (search.length) {
        loading(true);
        this.searchItem(loading, search, this);
      } else {
        loading(true);
        setTimeout(() => {
          this.optionsProduct = [];
          this.getData();
          loading(false);
        }, 500);
      }
    },
    searchItem: _.debounce((loading, search, vm) => {
      vm.$store
        .dispatch("cashier/getData", {
          uuid: "",
          params: {
            search: escape(search),
          },
        })
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          vm.optionsProduct = [];
          response.data.data.data.forEach((item) => {
            vm.optionsProduct.push({
              label: item.uuid,
              title: item.name,
            });
          });
          loading(false);
        });
    }, 300),
    async getUomDetail(id) {
      await this.$store
        .dispatch("uom/getData", {
          uuid: id,
          params: "",
        })
        .then((result) => {
          setTimeout(() => {
            this.optionUom.push({
              label: result.data.data.uuid,
              name: result.data.data.name,
            });
            const option = this.getUniqueListBy(this.optionUom, "label");
            this.optionUom = option;
          }, 500);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    onSearchUom(search, loading) {
      if (search.length) {
        loading(true);
        this.searchUom(loading, search, this);
      } else {
        loading(true);
        setTimeout(() => {
          this.optionUom = [];
          this.getUom();
          loading(false);
        }, 500);
      }
    },
    searchUom: _.debounce((loading, search, vm) => {
      vm.$store
        .dispatch("uom/getData", {
          uuid: "",
          params: {
            search: escape(search),
          },
        })
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          vm.optionUom = [];
          response.data.data.data.forEach((item) => {
            vm.optionUom.push({
              label: item.uuid,
              name: item.name,
            });
          });
          loading(false);
        });
    }, 300),
    async getUom() {
      this.$store
        .dispatch("uom/getData", {
          uuid: "",
          params: "",
        })
        .then((result) => {
          result.data.data.data.forEach((element) => {
            this.optionUom.push({
              label: element.uuid,
              name: element.name,
            });
          });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    removeA(arr) {
      // eslint-disable-next-line prefer-rest-params
      let what;
      const a = arguments;
      let L = a.length;
      let ax;
      while (L > 1 && arr.length) {
        // eslint-disable-next-line no-plusplus
        what = a[--L];
        // eslint-disable-next-line no-cond-assign
        while ((ax = arr.indexOf(what)) !== -1) {
          arr.splice(ax, 1);
        }
      }
      return arr;
    },
    // getWarehouse() {
    //   this.$store
    //     .dispatch("warehouse/getData", {
    //       params: {
    //         per_page: 50,
    //       },
    //     })
    //     .then((result) => {
    //       this.warehouses = result.data.data.data;
    //       // for (let index = 0; index < result.data.data.length; index++) {
    //       //   this.formPayloadCheck.initial_stocks.push({
    //       //     warehouse_uuid: "",
    //       //   });
    //       // }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    getQty(index) {
      const qty = document.getElementById(`qty-${index}`);
      qty.value = this.formatRupiah(qty.value.toString(), "Rp. ");
      this.formProducts.items[index].qty = qty.value;
    },
    getRupiahStandard() {
      const rupiah = document.getElementById("harga-jual-standard");
      rupiah.value = this.formatRupiah(rupiah.value.toString(), "");
      this.formPayloadCheck.standard_price = rupiah.value;
    },
    getRupiahMinimum() {
      const rupiah = document.getElementById("harga-jual-minimum");
      rupiah.value = this.formatRupiah(rupiah.value.toString(), "");
      this.formPayloadCheck.minimum_price = rupiah.value;
    },
    getRupiahBeli() {
      const rupiah = document.getElementById("harga-beli");
      rupiah.value = this.formatRupiah(rupiah.value, "");
      this.formPayloadCheck.buying_price = rupiah.value;
    },
    getPrice() {
      const price = document.getElementById("harga-beli");
      price.value = this.formatRupiah(price.value.toString(), "Rp. ");
      this.formPayloadCheck.buying_price = price.value;
    },
    getRupiah(index) {
      const rupiah = document.getElementById(`rupiah-${index}`);
      rupiah.value = this.formatRupiah(rupiah.value.toString(), "Rp. ");
      this.formProducts.items[index].price = rupiah.value;
    },
    formatRupiah(angka, prefix) {
      const number_string = angka.replace(/[^,\d]/g, "").toString();
      const split = number_string.split(",");
      const sisa = split[0].length % 3;
      let rupiah = split[0].substr(0, sisa);
      const ribuan = split[0].substr(sisa).match(/\d{3}/gi);

      if (ribuan) {
        const separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }

      rupiah = split[1] != undefined ? `${rupiah},${split[1]}` : rupiah;
      return prefix == undefined ? rupiah : rupiah || "";
    },
    getWarehouse() {
      this.$store
        .dispatch("warehouse/getData", {
          uuid: "",
          params: "",
        })
        .then((result) => {
          this.warehouseData = result.data.data;
          for (let index = 0; index < result.data.data.length; index++) {
            this.formPayloadCheck.initial_stocks.push({
              warehouse_uuid: "",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    createItem() {
      const form = this.preparePayloadProduct();
      form.append(
        "uom_uuid",
        this.formPayloadCheck.uom_uuid.label ||
          this.formPayloadCheck.uom_uuid.name ||
          this.formPayloadCheck.uom_uuid
      );
      this.isLoadingProduct = true;
      this.$store
        .dispatch("parentProduct/postData", {
          query: "",
          payload: form,
        })
        .then(async () => {
          this.$bvModal.hide("modal-add-product-is-null");
          successNotification(this, "Success", "Produk berhasil di buat");
          await this.$refs.productChild.getProduct();
          await this.clearWarehouse();
          this.formPayloadCheck = {
            ...this.formPayloadCheck,
            product_type: "parent",
            alias_name: "",
            uom_uuid: "",
            standard_price: "",
            minimum_price: "",
            buying_price: "",
          };
          this.isLoadingProduct = false;
        })
        .catch(() => {
          this.isLoadingProduct = false;
        });
    },
    // clearWarehouse() {
    //   for (let index = 0; index < this.warehouses.length; index++) {
    //     this.formPayloadCheck.initial_stocks[index].warehouse_uuid = "";
    //   }
    // },
    getDiscountStocks(value) {
      switch (value) {
        case value.length === 0:
          this.discount = [];
          this.summaryDiscount();
          break;
        case value.length > 0:
          this.discount = value;
          this.summaryDiscount();
          break;
        default:
          break;
      }
      // this.discounts = value
    },
    getAdditionalStocks(value) {
      switch (value) {
        case value.length === 0:
          this.additional_fee_amount = [];
          this.summaryAdditional();
          break;
        case value.length > 0:
          this.additional_fee_amount = value;
          this.summaryAdditional();
          break;
        default:
          break;
      }
      // this.discounts = value
    },
    addStockDiscount(discounts, index, price) {
      this.price = parseFloat(preformatFloat(price));
      this.discounts = discounts;
      this.$store.commit("stockWarehouse/setIndexDiscounts", index);
      this.$bvModal.show("modal-stock-discount");
    },
    addStockFeeAmount(additionalFee, index, price, includeFee) {
      this.price = parseFloat(preformatFloat(price));
      this.additional_fees = additionalFee;
      this.include_fee = includeFee;
      this.include_number = index;
      this.$store.commit("stockWarehouse/setIndexAdditionalFee", index);
      this.$bvModal.show("modal-additional-fee");
    },
    summaryDiscount() {
      let total = 0;

      for (let i = 0; i < this.formProducts.items.length; i++) {
        let { price, qty, discounts, is_bonus } = this.formProducts.items[i];
        const priceFormatted = parseFloat(preformatFloat(price.toString()));
        const qtyFormatted = parseFloat(preformatFloat(qty.toString()));

        let priceAfterReduce = priceFormatted;

        if (discounts && discounts.length > 0) {
          for (let index = 0; index < discounts.length; index++) {
            const element = discounts[index];
            if (element.type === "fixed" && element.discount > 0) {
              const fixedPrice = element.discount;
              priceAfterReduce -= fixedPrice;
            } else if (element.type === "percent" && element.discount > 0) {
              priceAfterReduce -= (element.discount / 100) * priceAfterReduce;
            }
          }

          // Calculate Total Discount
          this.totalDiscount[i] =
            priceFormatted * qtyFormatted - priceAfterReduce * qtyFormatted;
        } else {
          this.totalDiscount[i] = 0;
        }

        // Ensure additional fees are included
        this.total[i] =
          is_bonus == 0
            ? priceAfterReduce * qtyFormatted + this.totalAdditional[i]
            : 0;
        total += !is_bonus ? this.total[i] : 0;
      }

      const grandTotal = total + this.formProducts.additional_fee;
      this.$store.commit("stockWarehouse/setTotalProduct", grandTotal);
      localStorage.setItem("cash_total_payment", grandTotal);
      // this.totalDiscountJalan = grandTotal;
    },

    summaryAdditional() {
      let total = 0;

      for (let i = 0; i < this.formProducts.items.length; i++) {
        let { price, additional_fees, is_bonus } = this.formProducts.items[i];
        const priceFormatted = parseFloat(preformatFloat(price.toString()));

        let priceAfterIncrease = priceFormatted;
        let additionalAmount = 0;

        if (additional_fees && additional_fees.length > 0) {
          for (let index = 0; index < additional_fees.length; index++) {
            const element = additional_fees[index];
            additionalAmount += element.additional_fee_amount;
          }
        }

        this.totalAdditional[i] = additionalAmount;
        this.total[i] =
          is_bonus == 0 ? priceAfterIncrease + this.totalAdditional[i] : 0;
        total += !is_bonus ? this.total[i] : 0;
      }

      const grandTotal = total + this.formProducts.additional_fee;
      this.$store.commit("stockWarehouse/setTotalProduct", grandTotal);
      localStorage.setItem("cash_total_payment", grandTotal);
      // this.totalDiscountJalan = grandTotal;
    },

    async getData() {
      await this.$store
        .dispatch("cashier/getData", {
          uuid: "",
          params: {
            per_page: 50,
          },
        })
        .then((result) => {
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < result.data.data.data.length; index++) {
            const element = result.data.data.data[index];
            this.optionsProduct.push({
              title: element.alias_name,
              label: element.uuid,
            });
          }
        })
        .catch((err) => {
          this.isLoading = false;
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    repeateAgain() {
      this.formProducts.items.push({
        product_uuid: "",
        qty: 0,
        price: 0,
        include_ppn: 0,
        include_fee_in_avg_price: 0,
        discounts: [],
        additional_fees: [],
        is_bonus: 0,
        warehouse_uuid: "",
      });
      this.total.push(0);
      this.count.push(0);
      // this.discounts.push({
      //   type: 'percent',
      //   discount: 0,
      // })
      this.totalDiscount.push(0);
      this.totalAdditional.push(0);

      this.$nextTick(() => {
        if (this.$refs.row && this.$refs.row.length > 0) {
          this.trAddHeight(this.$refs.row[0].offsetHeight);
        }
      });
    },
    removeItem(index) {
      this.formProducts.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
    makeItemBonus(index) {
      this.formProducts.items[index].is_bonus = 1;
    },
    getIncludeFee(value, index) {
      this.formProducts.items[index].include_fee_in_avg_price = parseInt(
        value,
        10
      );
    },
    preparePayloadProduct() {
      const form = new FormData();
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayloadCheck) {
        // eslint-disable-next-line no-prototype-builtins
        if (
          this.formPayloadCheck.hasOwnProperty(key) &&
          this.formPayloadCheck[key] != null
        ) {
          if (
            key !== "initial_stocks" &&
            key !== "buying_price" &&
            key !== "minimum_price" &&
            key !== "standard_price" &&
            key !== "uom_uuid"
          ) {
            form.append(key, this.formPayloadCheck[key]);
          }
          if (
            key === "buying_price" ||
            key === "minimum_price" ||
            key === "standard_price"
          ) {
            form.append(
              key,
              parseFloat(preformatFloat(this.formPayloadCheck[key]))
            );
          }
        }
      }
      if (this.formPayloadCheck.initial_stocks) {
        // eslint-disable-next-line no-plusplus
        for (
          let index = 0;
          index < this.formPayloadCheck.initial_stocks.length;
          index++
        ) {
          const element = this.formPayloadCheck.initial_stocks[index];
          if (
            element.warehouse_uuid !== "" &&
            element.warehouse_uuid !== false
          ) {
            // eslint-disable-next-line no-restricted-syntax
            for (const key in element) {
              if (Object.hasOwnProperty.call(element, key)) {
                // eslint-disable-next-line no-use-before-define
                const items = element[key];
                form.append(`initial_stocks[${index}][${key}]`, items);
              }
            }
          }
        }
      }
      return form;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.disabled-span {
  pointer-events: none;
}

table {
  border-collapse: collapse;
  width: 100%;
  th,
  td {
    text-align: left;
    padding: 8px;
    border: unset;
  }

  th {
    background-color: transparent;
    color: #2b324f;
    position: sticky;
    top: 0;
  }
}

// th {
//   background-color: #FFFFFF !important;
//   color: #2B324F !important;
//   position: sticky !important;
//   top: 0 !important;
// }

#modal-add-product-is-null {
  .modal-dialog {
    .modal-content {
      background: #fcfcfc;
      border-radius: 16px;
      .modal-body {
        padding: 0;
        .form-group {
          label {
            font-size: 14px;
            color: $dark;
            font-weight: 600;
          }
          input {
            background: #e7e7ed;
            border: none;
            border-radius: 8px;
            height: 52px;
            color: $dark;
            font-weight: 600;
          }
        }
        .button__group {
          display: flex;
          width: 100%;

          .button__cancel {
            background-color: #ffffff !important;
            border-color: #fff !important;
            color: $dark !important;
            box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
            border-radius: 0px 0px 0px 16px;
            width: 100%;
            height: 72px;
            font-size: 16px;
            font-weight: 800;
          }

          .button__save {
            background-color: #45b6ab !important;
            border-color: #45b6ab !important;
            box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
            border-radius: 0px 0px 16px 0px;
            width: 100%;
            height: 72px;
            font-size: 16px;
            font-weight: 800;
          }
        }
      }
    }
  }
}

.custom__pricelist--row {
  max-width: 12rem !important;
}
.custom__ppn--row {
  max-width: 5rem !important;
}
.custom__pricelist--col {
  max-width: 9.5rem !important;
  margin-right: 2rem !important;
  .custom__input {
    max-width: 9.5rem !important;
  }
}
.custom__ppn--col {
  max-width: 5rem !important;
}

.form-purchase {
  overflow-y: scroll;
  height: unset;

  #dropdown-dropleft__BV_toggle_ {
    padding: 0;
  }
  .repeater-form {
    transition: 0.35s height;
  }

  .btn__add--product {
    background-color: #ffffff !important;
    border: 1px solid #e4e5ec !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04) !important;
    border-radius: 8px;
    font-weight: 800;
  }

  #dropdown-dropleft {
    .dropdown-toggle {
      border-color: transparent !important;
      background-color: transparent !important;
      color: $dark !important;

      &:hover {
        border-color: transparent !important;
        background-color: transparent !important;
        box-shadow: none !important;
      }
    }
  }
  .form-group {
    margin-bottom: 0;
  }
  .footer__purchase {
    // position: absolute;
    // bottom: 0;
    // left: 0;
    width: 100%;
    height: 144px;
    background: #ffffff;
    border: 1.5px solid #eee;
    // box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
  }
}
</style>
